import BaseLayout from './components/BaseLayout'
import { BrowserRouter } from 'react-router-dom'
import Background from './components/Backgound/Background'

function App () {
  return (
    <div>
      <BrowserRouter>
        <BaseLayout />
        <Background />
      </BrowserRouter>
    </div>
  )
}

export default App
