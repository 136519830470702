import React, { useRef, forwardRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { vertexShader, fragmentShader } from './shaders'
import * as THREE from 'three'
import gsap from 'gsap'

const Blob = ({ size, speed, position, rotation, rgb }, ref) => {
  const clock = new THREE.Clock()
  const material = useRef()

  const uniforms = {
    speed: 0,
    time: { value: 0 },
    pointscale: { calue: 0.2 },
    size: { value: size },
    perlins: { value: 1.5 },
    decay: { value: 1.2 },
    displace: { value: 0.5 },
    complex: { value: 0.65 },
    waves: { value: 3.0 },
    eqcolor: { value: 10 },
    rcolor: { value: rgb[0] },
    gcolor: { value: rgb[1] },
    bcolor: { value: rgb[2] },
    fragment: { value: true },
    points: { value: false },
    redhell: { value: true }
  }

  const animColor = (rgb, value) => {
    // Move Threejs Blobs
    const tl = gsap.timeline({
      defaults: {
        duration: 2,
        ease: 'none'
      }
    })

    tl.to(rgb, { value })
    return tl
  }

  let currentPath = null

  const setColor = ([r, g, b]) => {
    // o to 2.5
    // [1.5, 1.5, 1.5]; // yellow pink
    // [0, 1.5, 1.5]; // green blue
    // [1.5, 1.5, 0]; // orange yellow
    // [1.5, 1.5, 0.5]; // orange pink
    // [1.5, 2.5, 1]; // pink red
    // [2.5, 1.5, 2.5]; // bubblegum
    return (
      animColor(material.current.uniforms.rcolor, r),
      animColor(material.current.uniforms.gcolor, g),
      animColor(material.current.uniforms.bcolor, b)
    )
  }

  useFrame(() => {
    material.current.uniforms.time.value = clock.getElapsedTime() * speed
    if (currentPath !== window.location.pathname) {
      currentPath = window.location.pathname
      console.log(currentPath)
      switch (currentPath) {
        case '/':
          console.log('case /')
          setColor([1.5, 1.5, 0])
          break
        case '/about':
          setColor([1.5, 2, 1])
          break
        case '/portfolio':
          setColor([2.5, 2.5, 2.5])
          // setColor([0, 1.5, 1.5]); // blue
          break
        default:
          setColor([1.5, 1.5, 0])
      }
    }
  })

  return (
    <mesh ref={ref} position={position} rotation={rotation}>
      <icosahedronBufferGeometry attach='geometry' args={[2, 50]} />
      <shaderMaterial
        needsUpdate
        attach='material'
        ref={material}
        uniforms={uniforms}
        // Feed the shaders as strings
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        wireframe={false}
      />
    </mesh>
  )
}

export default forwardRef(Blob)
