import React from 'react'

export default function Loader () {
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='lds-spinner'>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
