import React, { useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import NavBar from './NavBar'
import Home from './Home/Home'
import About from './About/About'
import Portfolio from './Portfolio/Portfolio'

export default function BaseLayout () {
  const navigate = useNavigate()
  const location = useLocation()

  const links = [
    {
      name: 'Home',
      to: '/',
      active: 'home',
      className:
        'text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-orange-500'
    },
    {
      name: 'About',
      to: '/about',
      active: 'about',
      className:
        'text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-pink-800'
    },
    {
      name: 'Portfolio',
      to: '/portfolio',
      active: 'portfolio',
      className:
        'text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-fuchsia-600'
    }
  ]

  const swipeRoute = (direction) => {
    const actualNavIndex = links.findIndex(
      (link) => link.to === location.pathname
    )
    const newNavIndex = actualNavIndex + direction
    if (newNavIndex >= 0 && newNavIndex < links.length) {
      navigate(links[actualNavIndex + direction].to, { replace: true })
    }
  }

  const { ref: documentRef } = useSwipeable({
    onSwipedLeft: () => swipeRoute(1),
    onSwipedRight: () => swipeRoute(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  })

  useEffect(() => {
    documentRef(document)
  })

  return (
    <div>
      <header>
        <NavBar links={links} />
      </header>
      <main className='absolute h-full w-full flex justify-center items-center'>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/portfolio' element={<Portfolio />} />
        </Routes>
      </main>
    </div>
  )
}
