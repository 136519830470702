import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import me from '../../assets/images/me.jpg'
import Card from '../Card'

export default function About () {
  const [imageIsLoad, setImageIsLoad] = useState(false)
  const image = new Image()
  image.src = me
  image.onload = () => {
    setImageIsLoad(true)
  }

  return (
    <Card title='About me' height='h-fit'>
      <div className='h-full bg-gray-900 rounded-b-xl bg-opacity-30 p-6 max-w-sm md:max-w-max'>
        <div className='flex flex-col md:flex-row'>
          <div className='flex justify-center items-center'>
            {imageIsLoad
              ? (
                <img
                  className={`h-60 w-60 rounded-full border-8 border-opacity-40 grayscale ${!imageIsLoad && 'animate-pulse'}`}
                  src={imageIsLoad && image.src}
                  alt='profile'
                />
                )
              : (
                <div className='h-60 w-60 rounded-full border-8 border-opacity-40 animate-pulse' />
                )}
          </div>
          <div className='flex flex-col justify-center m-6'>
            <div className='text-6xl font-semibold'>Hi !</div>
            <div>
              <p>I'm a front-end developer from france.</p>
              <p>
                I'm passionate about UI/UX design and application development.
              </p>
              <p>I currently work on React JS.</p>
              <p>
                You can see my work on this{' '}
                <NavLink to='/portfolio' className='text-fuchsia-500'>
                  page
                </NavLink>{' '}
                and on my{' '}
                <a
                  href='https://github.com/alexislagodka/alexislagodka'
                  className='text-fuchsia-500'
                >
                  github
                </a>
                .
              </p>
              <p>
                You can contact me on{' '}
                <a
                  className='text-fuchsia-500'
                  href='https://www.linkedin.com/in/alexis-lagodka/'
                >
                  LinkedIn
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
