import React from 'react'

export default function Card ({ children, title, height }) {
  return (
    <div className={`flex flex-col relative overflow-hidden bg-gray-500 rounded-xl bg-opacity-40 backdrop-filter backdrop-blur-lg max-h-[85%] ${height}`}>
      <div className='flex justify-between font-semibold px-4 py-2'>
        <div className='grid grid-cols-3 gap-2 items-center pr-4'>
          <span className='h-3 w-3 rounded-full bg-red-500'>&nbsp;</span>
          <span className='h-3 w-3 rounded-full bg-yellow-500'>&nbsp;</span>
          <span className='h-3 w-3 rounded-full bg-green-500'>&nbsp;</span>
        </div>
        <div>{title}</div>
      </div>
      {children}
    </div>
  )
}
