import React, { useState, useEffect } from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'

export default function ProjectCard ({
  title,
  imageUrl,
  demoUrl,
  gitUrl,
  tags,
  description
}) {
  const [imageSrc, setImageSrc] = useState('')
  const [imageIsLoad, setImageIsLoad] = useState(false)
  const storage = getStorage()

  useEffect(() => {
    getDownloadURL(ref(storage, imageUrl))
      .then((url) => {
        setImageSrc(url)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [imageUrl, storage])

  const image = new Image()
  image.src = imageSrc
  image.onload = () => {
    setImageIsLoad(true)
  }

  return (
    <div
      className={`app-card relative rounded-xl w-60 h-44 flex flex-col
      transition duration-150 ease-out hover:scale-[1.02] bg-center bg-cover ${
        !imageIsLoad && 'animate-pulse'
      }`}
      style={{
        backgroundImage: `url(${imageIsLoad && image.src})`
      }}
    >
      <div className='absolute z-1 h-full w-full rounded-xl bg-gray-900 bg-opacity-50 grid grid-rows-[20px_1fr_40px] gap-3 p-4'>
        <div className='font-semibold'>{title}</div>
        <div className='border-b text-sm text-gray-200 line-clamp-3'>
          {description}
        </div>
        <div className='flex justify-end items-center'>
          <a
            className='bg-fuchsia-600 rounded-xl p-2 mx-1 hover:brightness-75'
            href={demoUrl}
          >
            Demo
          </a>
          <a
            className='bg-gray-300 rounded-xl p-2 mx-1 hover:brightness-75'
            href={gitUrl}
          >
            Code
          </a>
        </div>
      </div>
    </div>
  )
}
