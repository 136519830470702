import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export default function Home() {
  const [animate, setAnimate] = useState(true)

  useEffect(() => {
    if(window.sessionStorage.getItem("firstLoadDone") === null){
      setAnimate(true)
      window.sessionStorage.setItem("firstLoadDone", 1)
    } else {
      setAnimate(false)
    }
  }, []);

  return (
    <div className="container">
      <div className={ "scroller"}>
        <div className={animate ? "animate-scroll" : "inner"}>
          <span>&nbsp;</span>
          <span>Hello.</span>
          <span>I'm Alexis Lagodka.</span>
          <span>I'm a front-end developer</span>
          <span>
            Let's see&nbsp;
            <NavLink
              to="/portfolio"
              className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600"
            >
              my work
            </NavLink>
            .
          </span>
        </div>
      </div>
    </div>
  );
}
