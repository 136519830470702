import React from 'react'
import BlobCanvas from './BlobCanvas/BlobCanvas'

export default function Background () {
  return (
    <div className='absolute w-full h-full -z-50'>
      <BlobCanvas />
    </div>
  )
}
