import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function NavBar ({ links }) {
  const location = useLocation()
  const [active, setActive] = useState('')

  useEffect(() => {
    setActive(
      location.pathname === '/'
        ? 'home'
        : location.pathname.slice(1, location.pathname.length)
    )
  }, [location])

  return (
    <nav className='absolute text-lg font-semibold right-0 z-50'>
      <ul className='flex text-xl'>
        {links.map((link, index) => (
          <li
            className={
              link.active === active && !link.type ? link.className : undefined
            }
            key={index}
          >
            <Link to={link.to} onClick={() => setActive(link.active)}>
              {!link.type && (
                <div style={{ paddingBottom: '0.5rem' }}>{link.name}</div>
              )}
              {link.type && <h1>{link.name}</h1>}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
