import React, { useEffect, useState } from "react";
import Card from "../Card";
import ProjectCard from "../ProjectCard";
import Loader from "../Loader";
import app from "../../firebase";
import { getDatabase, get, ref, child } from "firebase/database";

export default function Portfolio() {
  const [isloading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [projectList, setProjectList] = useState(data);
  useEffect(() => {
    setIsLoading(true);
    const db = getDatabase(app);
    get(child(ref(db), "projects"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setData(data);
          setProjectList(data);
          setIsLoading(false);
        } else {
          console.log("no data available");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const filterByTitle = (title) => {
    const result = data.filter((project) => project.title === title);
    setProjectList(result);
  };

  return (
    <Card title="Portfolio" height="h-full">
      <div className="wrapper h-[85%] flex grow border-t border-gray-500">
        <div className="left-side border-r border-gray-500 p-6 overflow-y-auto shrink-0 hidden lg:block">
          <div className="side-wrapper">
            <button
              className="side-title mb-2 font-semibold text-gray-400"
              onClick={() => setProjectList(data)}
            >
              Apps
            </button>
            <div className="side-menu flex flex-col text-left">
              {data &&
                data.map((project, index) => (
                  <button
                    key={index}
                    onClick={() => filterByTitle(project.title)}
                    className="text-left"
                  >
                    {project.title}
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className="main-container flex flex-col grow">
          <div className="main-header flex justify-center items-center border-b border-gray-500 h-16 shrink-0">
            <div className="header-menu ml-2 flex">
              <div className="px-5 py-6">Desktop</div>
              <div className="px-5 py-6">Mobile</div>
              <div className="px-5 py-6">Web</div>
            </div>
          </div>
          <div className="content-wrapper flex flex-col bg-gray-900 rounded-br-xl bg-opacity-40 px-5 py-10 overflow-y-auto h-full">
            <div className="content-section flex flex-col grow">
              <div className="content-section-title mb-2 text-gray-400 font-semibold">
                Installed
              </div>
              {isloading ? (
                <Loader />
              ) : (
                <div className="lis-app grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {projectList &&
                    projectList.map((project, index) => (
                      <ProjectCard
                        key={index}
                        title={project.title}
                        imageUrl={project.image}
                        demoUrl={project.demoUrl}
                        gitUrl={project.gitUrl}
                        tags={project.tags}
                        description={project.description}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
