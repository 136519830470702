import React, { useRef, useEffect } from 'react'
import Blob from '../Blob/Blob'
import { Canvas } from '@react-three/fiber'
import { PerspectiveCamera } from '@react-three/drei'
import gsap from 'gsap'

export default function BlobCanvas () {
  return (
    <Canvas>
      <PerspectiveCamera
        fov={35}
        aspect={window.innerWidth / window.innerHeight}
        near={1}
        far={1000}
        position={[0, 0, 16]}
        makeDefault
      />
      <Scene />
    </Canvas>
  )
}

const Scene = () => {
  const sceneRef = useRef()
  const blob = useRef()
  const size = window.innerWidth > 1024 ? 1 : 1.5

  // Entry animation
  useEffect(() => {
    animBlob()
  }, [])

  const animBlob = () => {
    // Move Threejs Blobs
    const tl = gsap.timeline({
      defaults: {
        duration: 2,
        ease: 'Back.easeOut'
      }
    })

    tl.from(blob.current.position, { z: -20 })
    return tl
  }

  return (
    <group ref={sceneRef}>
      <Blob
        ref={blob}
        size={size}
        speed={0.2}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        rgb={[1.5, 1.5, 0]}
      />
    </group>
  )
}
